export const OPEN_SECTOR_CODES = 'open-sector-codes';

export const roles = [
  {label: 'Onbekend', value: 0},
  {label: 'Lid', value: 1},
  {label: 'Medewerker', value: 2},
  {label: 'Redacteur', value: 3},
  {label: 'Beheerder', value: 4},
  {label: 'Developer', value: 5},
];

export const rolesSelect = [
  {title: 'Geen', id: 0},
  {title: 'Kijker', id: 1},
  {title: 'Materiaalbeheerder', id: 2},
  {title: 'Projectbeheerder', id: 3},
  {title: 'Organisatiebeheerder', id: 4},
];

export const subscriptions = {
  standard: 'Standaard',
  pro: 'Pro',
  connect: 'Connect',
  pro_connect: 'Pro & Connect',
};


export const AMSTERDAM_ORGANISATION_ID = 29;
export const BUITENRUIMTE_SECTOR_ID = 4;
